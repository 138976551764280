// extracted by mini-css-extract-plugin
export var banner = "productinfo-module--banner--d7d61";
export var name = "productinfo-module--name--ddd6d";
export var sidepadding = "productinfo-module--sidepadding--7456e";
export var submenulist = "productinfo-module--submenulist--719f7";
export var menulistitemwithdivider = "productinfo-module--menulistitemwithdivider--2c10d";
export var subheading = "productinfo-module--subheading--53716";
export var sectionoddbg = "productinfo-module--sectionoddbg--a1fa0";
export var sectiontwocolumns = "productinfo-module--sectiontwocolumns--5b82b";
export var sectiontwocolumnscontent = "productinfo-module--sectiontwocolumnscontent--0a57e";
export var description = "productinfo-module--description--da479";
export var sectiondefaultsubtitle = "productinfo-module--sectiondefaultsubtitle--757d9";
export var techdataitem = "productinfo-module--techdataitem--7077b";
export var sectiondefaultbody = "productinfo-module--sectiondefaultbody--210b7";
export var techdataname = "productinfo-module--techdataname--235de";
export var techdatainfo = "productinfo-module--techdatainfo--5ef27";
export var techdatainfodata = "productinfo-module--techdatainfodata--2206a";
export var techdatainfodatasection = "productinfo-module--techdatainfodatasection--9fdff";
export var techdatainfodatasectiontitle = "productinfo-module--techdatainfodatasectiontitle--72136";
export var linkitem = "productinfo-module--linkitem--18e8d";
export var actionarea = "productinfo-module--actionarea--1779c";
export var msdsarea = "productinfo-module--msdsarea--913d5";
export var productpaintcanarea = "productinfo-module--productpaintcanarea--1740d";
export var productdescriptionarea = "productinfo-module--productdescriptionarea--9ed79";
export var linkarea = "productinfo-module--linkarea--7ed07";