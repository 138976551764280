// extracted by mini-css-extract-plugin
export var banner = "colorcollectionpage-module--banner--ea83b";
export var title = "colorcollectionpage-module--title--a86b8";
export var colorlist = "colorcollectionpage-module--colorlist--9645d";
export var colortrendlinks = "colorcollectionpage-module--colortrendlinks--a1d0b";
export var linkitemdivider = "colorcollectionpage-module--linkitemdivider--79a59";
export var sidepadding = "colorcollectionpage-module--sidepadding--526e4";
export var description = "colorcollectionpage-module--description--96331";
export var linkitem = "colorcollectionpage-module--linkitem--8acab";
export var subtitle = "colorcollectionpage-module--subtitle--5ed69";
export var colortrendsubtitle = "colorcollectionpage-module--colortrendsubtitle--8cae0";
export var colortrenddefaultinfocolor = "colorcollectionpage-module--colortrenddefaultinfocolor--3f7ca";
export var colortrend2024infocolor = "colorcollectionpage-module--colortrend2024infocolor--6f3ed";
export var linkarea = "colorcollectionpage-module--linkarea--b7221";