import React from "react"
import {graphql, Link} from "gatsby"
import Layout from "../components/layout"

import ProductSectionDefault from "../components/paintingtools/productsection/productsectiondefault"
import Colorlist from "../components/colorlist"

import formatBOYSEN from "../../lib/formatBoysen"

import * as styles from "../styles/pages/colorcollectionpage.module.css"

const listproduct = require('../content/data/listproduct.json');

const COLORTRENDKEY = " color trend ";

const ColorcollectionTemplate = ({pageContext, data}) => {
	var extrastyle="";
	const descriptiontitle = "DESCRIPTION";
	const productdetail = data.detail.edges[0].node.childrenJson[0].info;
	const productlink = data.detail.edges[0].node.childrenJson[0].linkdata;
	var descriptionbody = "";
	var tmpidx = pageContext.item.name.toLowerCase().indexOf(COLORTRENDKEY);
	var colortrendyear = 0;
	if (tmpidx > 0) {
		colortrendyear = parseInt(pageContext.item.name.substring(tmpidx+COLORTRENDKEY.length),10);
	}

	tmpidx = 0;
	while (tmpidx < productdetail.length) {
		if (productdetail[tmpidx].title === descriptiontitle) {
			descriptionbody = productdetail[tmpidx].body;
		}
		tmpidx++;
	}

	if (colortrendyear > 0 && descriptionbody.length > 0) {
		var newlineidx = 0;
		var tmpstr = "";
		tmpidx = descriptionbody.lastIndexOf(" and ");
		newlineidx = descriptionbody.substring(0, tmpidx).lastIndexOf("\n");
		tmpstr = descriptionbody.substring(0, newlineidx+1)+"<span class=\"font-style-bold\">";
		tmpstr = tmpstr+descriptionbody.substring(newlineidx+1, tmpidx)+"</span> and <span class=\"font-style-bold\">";
		tmpstr = tmpstr+descriptionbody.substring(tmpidx+5);
		descriptionbody = tmpstr;
	}

	if (colortrendyear > 0) {
		if (colortrendyear === 2024) {
			extrastyle = " "+styles.colortrend2024infocolor;
		} else {
			extrastyle = " "+styles.colortrenddefaultinfocolor;
		}
	}
	return (
		<Layout activePath={"colorcollection/"+pageContext.item.url} pageImage={pageContext.item.imageweburl} pageTitle={formatBOYSEN.stripNameTags( pageContext.item.name+" "+pageContext.item.subtitle)} pageDescription={descriptionbody.length>0?formatBOYSEN.stripNameTags(descriptionbody):formatBOYSEN.stripNameTags( pageContext.item.name+" "+pageContext.item.subtitle)}>
			{colortrendyear>0 && <div className={"text-centered "+styles.colortrendlinks}>
				{listproduct.listproductobj.map((product, idx)=>{
					var linkyear = 0;
					var tmpidx = product.name.toLowerCase().indexOf(COLORTRENDKEY);
					if (tmpidx > 0) {
						linkyear = parseInt(product.name.substring(tmpidx+COLORTRENDKEY.length),10);
					}
					if (linkyear === 0) {
						return <></>
					}
					var classstr = (linkyear===colortrendyear?"textcolor-primary":"textcolor-secondary")+(linkyear!==2024&&linkyear!==2018?" "+styles.linkitemdivider:"");
					var linkdisplay = linkyear >= 2020?(linkyear+" - "+(1+linkyear)):linkyear
					return <>
						{linkyear===2018&&<br/>}
						<Link to={"/colorcollection/"+product.url} className={classstr}>{linkdisplay}</Link>
					</>
				})}
			</div>}
			<div className={styles.banner}>
				<img src={pageContext.item.imageweburl} />
			</div>
			{pageContext.item.imagelogourl.length > 0 && <>
				<h1 className={"text-centered "+styles.title} >
					<img src={pageContext.item.imagelogourl} alt={formatBOYSEN.stripNameTags( pageContext.item.name+" "+pageContext.item.subtitle)} />
				</h1>
			</>}
			{colortrendyear === 0 ?
				<div className={"text-centered "+styles.subtitle}>{data.color.edges[0].node.childrenJson.length} Colors</div>
			:
				<div className={styles.colortrendsubtitle+" text-centered "+styles.subtitle+extrastyle}>COLOR TREND {(colortrendyear<2020)?colortrendyear:colortrendyear+" - "+(colortrendyear+1)}</div>
			}
			{(descriptionbody.length > 0) && <div className={styles.sidepadding+extrastyle}>
				<ProductSectionDefault className={styles.description} contentbodylist={[
					{"subtitle":"", "body":descriptionbody},
					//{"subtitle":"", "body":principalusebody}
				]} />
			</div>}
			{productlink.length > 0 && <div className={styles.sidepadding+" "+styles.linkarea+" text-centered"+extrastyle}>
				{productlink.map((linkitem, linkitemidx)=>{
					return <a className={styles.linkitem} title={linkitem.title} href={linkitem.url} target="_blank" rel="noreferrer">{linkitem.title}</a>
				})}
			</div>}

			<div className={styles.colorlist}>
				<Colorlist listobj={data.color.edges[0].node.childrenJson} columntype={"collection"} listid={"colorcollection/"+pageContext.item.url} />
			</div>
		</Layout>
	)
}

export default ColorcollectionTemplate


// Accessed as "data"
export const querydata = graphql`
	query ($detail: String!, $color: String!) {
		detail: allFile(
			filter: {sourceInstanceName: {eq: "productdata"}, name: {eq: $detail} }
		) {
			edges {
				node {
					childrenJson {
						info {
							body
							subtitle
							title
							variant
						}
						linkdata {
							url
							title
						}
					}
				}
			}
		}
		color: allFile(
			filter: {sourceInstanceName: {eq: "productdata"}, name: {eq: $color} }
		) {
			edges {
				node {
					childrenJson {
						jsonId
						code
						name
						variant
						imageurl
						red
						green
						blue
					}
				}
			}
		}
	}
`