import React from "react"

import * as styles from "../productinfo.module.css"

const ProductSectionDefault = ({ contentbody, contentbodylist, className }) => {
	const targetclassname = typeof className==="undefined"?"":className;

	const splitbodytodiv = function(tmptargetclassname, bodymarkup) {
		return <div className={tmptargetclassname.length>0?tmptargetclassname+" "+styles.sectiondefaultbody:styles.sectiondefaultbody}>
		{bodymarkup.split("\n").map((tmpbody, tmpbodyidx)=> {
			return <div dangerouslySetInnerHTML={{__html:tmpbody}} />
		})}

	</div>
	}

	return (<>
		{typeof contentbodylist !== "undefined" ? <div className={targetclassname}>
			{contentbodylist.map((curbody, curbodyidx)=>{

				if (curbody.body.length <= 0) {
					return <></>
				}
				return <>
					{curbody.subtitle.length > 0 && <h3 className={"font-style-bold "+styles.sectiondefaultsubtitle} dangerouslySetInnerHTML={{__html: curbody.subtitle}} />}
					{
						splitbodytodiv("",curbody.body)
						//return <div className={styles.sectiondefaultbody} dangerouslySetInnerHTML={{__html:"<div >"+curbody.body.replace(/\n/g, "</div>\n<div>")+"</div>"}} />
					}
				</>

			})}
		</div>:
		<>
			{
				splitbodytodiv(targetclassname,contentbody)
				//return <div className={styles.sectiondefaultbody} dangerouslySetInnerHTML={{__html:"<div >"+curbody.body.replace(/\n/g, "</div>\n<div>")+"</div>"}} />
			}
		</>
		}
	</>)
}

export default ProductSectionDefault
